import React, { useState, createContext, useEffect, useRef } from 'react'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import { AnimatePresence, motion } from 'framer-motion'

import { ButtonRound } from 'components/Buttons'
import {
    Modal as animModal,
    ModalCover as animModalCover,
    ModalTranstion as animModalTransition,
    ModalInner as animModalInner
} from './animations/index'

interface ModalProviderProps {
    children: any
}

export interface I_ContextState {
    isVisible: boolean
    content: any
    setContent: (content: React.ReactNode) => void
    castModal: () => void
}

const defaultState = {
    isVisible: false,
    content: <div>test</div>,
    setContent: (content: React.ReactNode) => {},
    castModal: () => {}
}

export const ModalContext = createContext(defaultState)

export const ModalProvider = ({ children }: ModalProviderProps) => {
    const refModal = useRef<any>(null)
    const [state, setState] = useState<I_ContextState>(defaultState)

    const setContent = (content: React.ReactNode) => setState(s => ({ ...s, content }))
    const castModal = () => setState({ ...state, isVisible: !state.isVisible })

    useEffect(() => {
        if (refModal) {
            state.isVisible ? disableBodyScroll(refModal.current, { reserveScrollBarGap: true }) : clearAllBodyScrollLocks()
            refModal
        }
    }, [state])

    const closeModal = () => setState({ ...state, isVisible: !state.isVisible })

    return (
        <ModalContext.Provider value={{ ...state, castModal, setContent }}>
            <>
                {children}
                <AnimatePresence>
                    {state.isVisible && (
                        <>
                            <motion.div
                                ref={refModal}
                                className="modal"
                                initial={'in'}
                                animate={'animate'}
                                exit={'in'}
                                variants={animModal}
                                transition={{ ...animModalTransition }}
                            >
                                <ButtonRound classes="modal__button" title={'×'} delegateClick={() => closeModal()} />
                                <motion.div
                                    className="modal__inner"
                                    initial={'in'}
                                    animate={'animate'}
                                    exit={'exit'}
                                    variants={animModalInner}
                                    transition={{ ...animModalTransition, duration: 1.25 }}
                                >
                                    {state.content}
                                </motion.div>
                                <motion.div
                                    initial={'in'}
                                    animate={'animate'}
                                    exit={'in'}
                                    variants={animModalCover}
                                    transition={{ ...animModalTransition, duration: 0.5, delay: 0.5 }}
                                    className="modal-cover"
                                    onClick={() => closeModal()}
                                />
                            </motion.div>
                        </>
                    )}
                </AnimatePresence>
            </>
        </ModalContext.Provider>
    )
}

export default ModalProvider
// export default ({ element }: any) => {
//     return <ModalProvider>{element}</ModalProvider>
// }
