/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from 'react'
import { AnimatePresence } from 'framer-motion'

import GlobalProvider from './src/context/Global'
import ModalProvider from './src/context/Modal'

const transitionDelay = 650

export const wrapRootElement = ({ element }) => {
    return (
        <GlobalProvider>
            <ModalProvider>{element}</ModalProvider>
        </GlobalProvider>
    )
}

export const wrapPageElement = ({ element }) => <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    if (location.action === 'PUSH') {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
        const savedPosition = getSavedScrollPosition(location)
        window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), transitionDelay)
    }
    return false
}
