import React, { useState, createContext, useEffect, useRef } from 'react'

interface GlobalProviderProps {
    children: any
}

export interface I_GlobalInfo {
    id: string
    language: string
    pathname: string
    title: string
    description: string
    isInverted: boolean
}

export interface I_ContextState extends I_GlobalInfo {
    setGlobal: (globalState: I_GlobalInfo) => void
}

const defaultState: I_ContextState = {
    id: 'c646c343-21e8-4fb5-afcb-157153ddc402',
    pathname: '/en',
    language: 'en',
    title: 'home',
    description: 'Leading in Rayon',
    isInverted: false,
    setGlobal: (gS: I_GlobalInfo) => {}
}

export const GlobalContext = createContext(defaultState)

export const GlobalProvider = ({ children }: GlobalProviderProps) => {
    const [state, setState] = useState<I_ContextState>(defaultState)

    const setGlobal = (gS: I_GlobalInfo) => {
        const { language, description, title, isInverted, id, pathname } = gS
        setState({ ...state, language, title, description, isInverted, id, pathname })
    }

    return <GlobalContext.Provider value={{ ...state, setGlobal }}>{children}</GlobalContext.Provider>
}

export default GlobalProvider
