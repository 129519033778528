import { Variants, Transition } from 'framer-motion'

export const Modal: Variants = {
    in: {
        opacity: 1
    },
    animate: {
        opacity: 1
    }
}

export const ModalInner: Variants = {
    in: {
        y: '100%',
        scaleX: 0.5,
        opacity: 0.25
    },
    animate: {
        y: '0%',
        scaleX: 1,
        opacity: 1
    },
    exit: {
        y: '50%',
        scaleX: 0.85,
        opacity: 0
    }
}

export const ModalCover: Variants = {
    in: {
        opacity: 0
    },
    animate: {
        opacity: 1
    }
}

export const ModalTranstion: Transition = {
    duration: 0.75,
    ease: 'easeInOut'
}
