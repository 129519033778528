import React from 'react'
import { Illustrations } from 'components/Features/Illustrations'

export interface ButtonRoundProps {
    classes?: string
    title: string
    delegateClick: (e: React.MouseEvent<HTMLElement>) => void
}

export const ButtonRound: React.FC<ButtonRoundProps> = ({ classes, title, delegateClick }) => {
    const renderInner = (title: string) => {
        switch (title) {
            case '←':
                return <Illustrations classes="button-round__icon" type={'arrow-left'} />
            case '→':
                return <Illustrations classes="button-round__icon" type={'arrow-right'} />
            default:
                return <span className="button-round__inner h4">{title}</span>
        }
    }
    return (
        <button type={'button'} onClick={e => delegateClick(e)} className={`button-round ${classes ? classes : ''}`}>
            {renderInner(title)}
        </button>
    )
}
